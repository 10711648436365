<template>
    <div class="user-container">
    <CTabs :active-tab.sync="activeTab">
        <CTab>
            <template slot="title">Kategori Tiket Pengguna</template>
            <CRow>
                <CCol col="12">
                    <CCard class="p-0">
                        <CCardHeader>
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h5>Kategori Tiket Pengguna</h5>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <category-ticket-table :is-system-ticket-prop="false"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CTab>
        <CTab v-if="isAdmin">
            <template slot="title">Kategori Tiket Sistem</template>
            <CRow>
                <CCol col="12">
                    <CCard class="p-0">
                        <CCardHeader>
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h5>Kategori Tiket Sistem</h5>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <category-ticket-table :is-system-ticket-prop="true"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CTab>
        <CTab>
            <template slot="title">Prioritas Tiket Pengguna</template>
            <CRow>
                <CCol col="12">
                    <CCard class="p-0">
                        <CCardHeader>
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h5>Prioritas Tiket Pengguna</h5>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <priority-ticket-table :is-system-ticket-prop="false"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CTab>
     <CTab v-if="isAdmin">
            <template slot="title">Prioritas Tiket Sistem</template>
            <CRow>
                <CCol col="12">
                    <CCard class="p-0">
                        <CCardHeader>
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h5>Prioritas Tiket Sistem</h5>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <priority-ticket-table :is-system-ticket-prop="true"/>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CTab>
    </CTabs>
    </div>
</template>
    
<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import CategoryTicketTable from '@/views/ticket/CategoryTicketTable.vue'
import PriorityTicketTable from '@/views/ticket/PriorityTicketTable.vue'
export default {
        names: 'SettingTicket',
        components: {
            'category-ticket-table': CategoryTicketTable,
            'priority-ticket-table': PriorityTicketTable
        },
        data () {
            return {
                isAdmin: false
            }
        },
        watch: {
        },
        mounted () {
            this.isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
        },
        methods: {
        }
    }

</script>

<style scoped>

</style>