<template>
    <div class="user-container">
        <CRow>
            <CCol col="12">
                <v-data-table
                    :loading="loading"
                    loading-text="Memuat Data..."
                    :headers="headers"
                    :options.sync="optionsTable"
                    :server-items-length="totalData"
                    :multi-sort="false"
                    :items="categoryTicketList"
                    class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                            <v-icon v-c-tooltip="'Buka detil kategori tiket'" small class="mr-2 text-info" @click="showInfoPopUp(item)">
                                mdi-file-find
                            </v-icon>
                        </template>
                        <template v-slot:no-data>
                            <span>Data Tidak Ditemukan</span>
                        </template>
                </v-data-table>
            </CCol>
        </CRow>
        <!-- Popup Modal & Information -->
        <CModal :show.sync="info.showPopUpModal" :closeOnBackdrop="false">
            <template #header>
                <h5 class="modal-title">Info Kategori <b>{{info.category}}</b></h5>
            </template>
            <div class="p-1">
                <b>Prioritas</b>: {{info.priority}}
                <hr>
                <h6>Deskripsi</h6>
                <small>{{info.description}}</small>
            </div>
            <template #footer>
                <CButton @click="closePopupModal()" class="btn-sm">Tutup</CButton>
            </template>
        </CModal>
    </div>
</template>
    
<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { mapState } from 'vuex'

const avatar = require('@/assets/img/avatar.svg')
export default {
    names: 'CategoryTicketTable',
    props: {
        isSystemTicketProp: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            totalData: 0,
            categoryTicketList: [],
            loading: false,
            headers: [
                { text: 'Nama Kategori', value: 'category_name', align: 'center', sortable: true },
                { text: 'Prioritas', value: 'priority', align: 'center', sortable: true },
                { text: 'Aksi', value: 'actions', sortable: false, align: 'center' }
            ],

            optionsTable:{},
            info: {
                showPopUpModal: false,
                category: '',
                description: 'Deskripsi Tidak Tersedia',
                priority: 0,
            },
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserId: 'user_id',
            loggedUserPosition: 'position',
            fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64',
            email: 'email'
        }),
    },
    watch: {
        optionsTable: {
            deep: true,
            handler () {
                this.getCategoryTicketDataList()
            }
        },
    },
    mounted () {
        this.getCategoryTicketDataList()
    },
    methods: {
        getCategoryTicketDataList() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.optionsTable

            var ticketType = 1
            if(this.isSystemTicketProp) {
                ticketType = 2
            }
            
            this.$store.dispatch(
                {
                    type: 'ticket/getCategoryTicketList',
                    pagination: {
                        limit: itemsPerPage,
                        offset: (page-1)*itemsPerPage,
                    },
                    ticketType: ticketType
                }
            ).then(res=> {
                this.categoryTicketList = res.result
                this.totalData = res.result.length
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal mengambil data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
                this.loading = false
            })
        },
        
        showInfoPopUp(item) {
            this.info.showPopUpModal = true

            this.info.category = item.category_name
            this.info.priority = item.priority
            if (item.description && item.description != ''){
                this.info.description = item.description
            } else {
                this.info.description = 'Deskripsi Tidak Tersedia'
            }
        },
        
        closePopupModal() {
            this.showPopUpModal = false
            this.info.showPopUpModal = false
        },
    }
}

</script>

<style scoped>

</style>