<template>
    <div class="user-container">
        <CRow>
            <CCol col="12">
                <v-data-table
                    :loading="loading"
                    loading-text="Memuat Data..."
                    :headers="headersPriority"
                    :options.sync="optionsTable"
                    :server-items-length="totalData"
                    :multi-sort="false"
                    :items="priorityList"
                    class="elevation-1">
                        <template v-slot:no-data>
                            <span>Data Tidak Ditemukan</span>
                        </template>
                </v-data-table>
            </CCol>
        </CRow>
    </div>
</template>
    
<script>
import { mapState } from 'vuex'

const avatar = require('@/assets/img/avatar.svg')
export default {
    names: 'PriorityTicketTable',
    props: {
        isSystemTicketProp: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            loading: false,

            optionsTable:{},

            activeTab: 0,

            priorityList: [],
            totalDataPriority: 0,
            headersPriority: [
                { text: 'Tingkat Prioritas', value: 'priorityName', align: 'center', sortable: true, width: 200 },
                { text: 'Deskripsi', value: 'description', align: 'center', sortable: true }
            ],
            priorityData: {
                priorityName: '',
                priorityId: 0,
                priority: 0,
                description: ''
            },
        }
    },
    computed: {
        ...mapState('auth', {
            loggedUserId: 'user_id',
            loggedUserPosition: 'position',
            fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64',
            email: 'email'
        }),
    },
    watch: {
        optionsTable: {
            deep: true,
            handler () {
                this.getPriorityList()
            }
        },
    },
    mounted () {
            this.getPriorityList()
    },
    methods: {
        getPriorityList() {
            this.totalDataPriority = this.priorityList.length

            var groupName = 'Prioritas Tiket'
            if(this.isSystemTicketProp) {
                groupName = 'Prioritas Tiket Sistem'
            }
            this.$store.dispatch(
                {
                    type: 'summary/getVarData',
                    filter: {
                        group: groupName
                    }
                }
            ).then(res=> {
                this.priorityList = []
                let items = res.result
                if (items.length > 0) {
                    items.forEach((item) => {
                        let elm = {
                            priorityName : item.key,
                            priorityId : item.key_id,
                            priority : item.key,
                            description : item.value
                        }
                        this.priorityList.push(elm)
                    })
                }
            }).catch( err => {
                console.log(err)
                this.$toasted.error('Gagal mengambil data, silahkan coba kembali!').goAway(3000)
            }).finally(() => {
            })
        }
    }
}

</script>

<style scoped>

</style>